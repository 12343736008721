module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Brazil Steakhouse","short_name":"Brazil Steakhouse","start_url":"/","background_color":"#1f1e1e","theme_color":"#1f1e1e","display":"standalone","icon":"./src/favicon.png","icons":[{"src":"./src/favicon.png","sizes":"512x512","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"31acfab73c3877efb8b4ad3ff8605f81"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"StaleWhileRevalidate"},{"urlPattern":{},"handler":"NetworkFirst","options":{"networkTimeoutSeconds":10}}]}},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.brazilsteakhouse95624.com","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"1017517359791296"},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KSQ62RW","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    }]
